<template>
<div>
  <HeaderView :page_index="2" />
  <div class="home">
    <el-container>
      <el-container>
        <el-aside width="200px">
          <ul  id="powers">
            <!-- <li >
              <div class="border_bottom" style="height: 50px;" @click="click_tab_name('全部')"><span>全部</span></div>
            </li> -->
            <li >
              <div class="left">
                <div class="border_right" style="height: 100px;" @click="click_tab_name('东周')"><span>东周</span></div>
              </div>
              <div class="right">
                <div class="border_bottom" style="height: 50px;" @click="click_tab_name('春秋')"><span>春秋</span></div>
                <div class="" style="height: 50px;" @click="click_tab_name('战国')"><span>战国</span></div>
              </div>
            </li>
            <li >
              <div class="heng2 border_top" style="height: 25px;" @click="click_tab_name('秦朝')"><span>秦朝</span></div>
            </li>
            <li >
              <div class="heng2 border_top" style="height: 80px;" @click="click_tab_name('西汉')"><span>西汉</span></div>
            </li>
            <li >
              <div class="heng2 border_top" style="height: 25px;" @click="click_tab_name('新朝')"><span>新朝</span></div>
            </li>
            <li >
              <div class="heng2 border_top" style="height: 70px;" @click="click_tab_name('东汉')"><span>东汉</span></div>
            </li>
            <li>
              <div>
                <div class="heng2 border_top" style="height: 40px;" @click="click_tab_name('三国')"><span>三国</span></div>
              </div>
              <div class="left">
                <div class="border_bottom border_top border_right" style="height: 30px;" @click="click_tab_name('西晋')"><span>西晋</span></div>
                <div class="border_bottom border_right" style="height: 40px;" @click="click_tab_name('东晋')"><span>东晋</span></div>
                <div class="border_right" style="height: 9px;" @click="click_tab_name('南北朝')"></div>
              </div>
              <div class="right">
                <!-- <div class="" style="height: 10px;" @click="click_tab_name('三国')">x</div> -->
                <div class="" style="height: 10px;" @click="click_tab_name('三国')"></div>
                <div class=" border_top border_bottom" style="height: 70px;" @click="click_tab_name('十六国')"><span>十六国</span></div>
              </div>
              <div>
                <div class="heng2 border_bottom" style="height: 60px;" @click="click_tab_name('南北朝')"><span>南北朝</span></div>
              </div>
            </li>

            <li >
              <div class="heng2" style="height: 30px;" @click="click_tab_name('隋朝')"><span>隋朝</span></div>
            </li>
            <li >
              <div class="heng2 border_top border_bottom" style="height: 80px;" @click="click_tab_name('唐朝')"><span>唐朝</span></div>
            </li>
            <li>
              <div class="left">
                <div class="border_bottom border_right" style="height: 30px;" @click="click_tab_name('五代十国')"><span>五代十国</span></div>
                <div class="border_bottom border_right" style="height: 50px;" @click="click_tab_name('北宋')"><span>北宋</span></div>
                <div class="border_bottom border_right" style="height: 50px;" @click="click_tab_name('南宋')"><span>南宋</span></div>
              </div>
              <div class="right">
                <div class="border_bottom" style="height: 75px;" @click="click_tab_name('辽')"><span>辽</span></div>
                <div class="border_bottom" style="height: 40px;" @click="click_tab_name('金')"><span>金</span></div>
                <div class="" style="height: 15px;" @click="click_tab_name('蒙古')"><span>蒙古</span></div>
              </div>
            </li>
            <li >
              <div class="heng2 " style="height: 75px;" @click="click_tab_name('元朝')"><span>元朝</span></div>
              <div class="left">
                <div class="border_bottom" style="height: 15px;" @click="click_tab_name('元朝')">北元</div>
              </div>
              <div class="right">
                <!-- <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('明朝')">x</div> -->
                <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('明朝')"></div>
              </div>
            </li>
            <li >
              <div class="heng2 " style="height: 80px;" @click="click_tab_name('明朝')"><span>明朝</span></div>
              <div class="left">
                <div class="border_bottom" style="height: 15px;" @click="click_tab_name('明朝')">南明</div>
              </div>
              <div class="right">
                <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('清朝')">后金</div>
              </div>
            </li>
            <li >
              <div class="heng2" style="height: 80px;" @click="click_tab_name('清朝')"><span>清朝</span></div>
            </li>
            </ul>
        </el-aside>
        <el-container >
          <el-main style="text-align: left;">
            <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left; margin: 30px 30px 30px 20px;" />
            <div v-else  style="text-align: left; margin: 0px 30px 30px 20px;" >
              <div v-for="(power_type, index) in list" :key="index" >
                <div  style="margin: 15px 20px 10px 0px;"> <el-tag class="ml-2" size="large">{{ power_type.word }}</el-tag></div>
                <OnePower :power_info="info"  v-for="(info, index) in power_type.list" :key="index"  />
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import OnePower from '@/components/items/OnePower'
// import router from '@/router/index.js'
import HeaderView from '@/components/other/HeaderView'

export default {
  name: 'Power_index',
  mounted () {
    this.get_index()
  },
  components: {
    OnePower, HeaderView
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      list: [],
      in_where: null,
      not_in_where: null,
      in_my: null,
      fix: null
    }
  },
  methods: {
    click_tab_name (e) {

    },
    get_index () {
      axios.post(Utils.check_is_niming() ? '/power_index_unlogin/' : '/power_index/', { })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.loading = false
          this.list = res.data.data.list
        })
    }
  }
}
</script>
<style scoped>
.tab2{
  display: block;
  text-align: left;
  padding: 30px 40px 30px 20px;
}
.tab2 .el-tag,.tab2  .el-dropdown{
  vertical-align: middle;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}
.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
li{
  background: lightblue;
}
li div.border_bottom{
  border-bottom:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_top{
  border-top:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_right{
  border-right:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_left{
  border-left:  1px solid rgba(0, 0, 0, 0.08);
}
li div{
  /* vertical-align: middle; */
  /* display:table-cell; */
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor:pointer; */
}
li div.left,li div.right{
  display: inline-block;
  width: 98px;
  vertical-align: top;
}
li .heng1{
  /* display: inline-block; */
  /* width: 80px; */
}
li .heng2{
  width: 200px;
}
/* li div span.power_select{
  color:#ffd04b;
  font-size: 20px;
} */
#powers{
  font-size: 14px;
}
#powers  span.power_select{
  color:#50b7c1;
}
#powers  .power_select{
  color:#50b7c1;
}
/* #powers  span.name{
  color:#ffd04b;
  font-size: 20px;
} */
li .num{
  color: rgba(0, 0, 0, 0.32);
  font-size: 12px;
  display: inline-block;
  margin-left: 4px;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}

</style>
