<template>
  <router-link target="_blank" :to="{ name: 'Power_detail', params: { id: info.id }}"  class="power_view" >
    <!-- <div class="power_view"  @click="go_to('Power_detail')"> -->
    <div class="one_power" >
      <div class="line0" style="margin-bottom: 6px;">
        <span class="title">{{info.word}}</span>
        <div v-if="info.num_huang" style="display: inline-block; margin: 0px 6px; vertical-align: middle;">
          <span style="color: #ededed;">{{ info.num_huang }}代</span>
          <!-- <span style="color: fefefe;">代</span> -->
        </div>
      </div>

      <div class="name" >
        <div class="years">
          <text>{{info.year_start}}</text>
          <text>~</text>
          <text>{{info.year_end}}</text>
        </div>
      </div>
      <div class="name" >
        <text :class="['minzu_' + info.tag_minzu ]"> {{info.founder_person_name}}</text>
      </div>

      <div class="circlex">
        <div class="progress-wrapper">
            <el-progress type="circle" :indeterminate="true"  :show-text="false" color="#50b7c1" :stroke-width="8" :percentage="100 * (info.year_long / 300 <= 1 ? info.year_long / 300 : 1) "   />
            <div class="circle_text"><span> {{info.year_long }}</span>年</div>
        </div>
      </div>

    </div>
  </router-link>
</template>
<script>
// import router from '@/router/index.js'
import Utils from '@/utils/utils'
import Clipboard from 'clipboard'
import axios from 'axios'

export default {
  name: 'OnePower',
  props: {
    power_info: Map,
    need_show_more: Boolean
  },
  data () {
    return {
      info: this.power_info,
      show: false,
      loading: true,
      detail_info: {}
    }
  },
  methods: {
    to_show_more () {
      this.show_more = true
    },
    pull_up (e) {
      this.show = true
      axios.post('/power_detail/', { id: this.info.id })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.detail_info = res.data.data.info
          this.loading = false
          console.log(res.data.data)
        })
    },
    copy_word (e) {
      Utils.alert_success('已复制到剪切板')
      const clipboard = new Clipboard('.want_copy_word')
      clipboard.on('success', (e) => {
        // 释放内存
        clipboard.destroy()
      })
    },
    go_to (pageName) {
      let routeData = null
      if (pageName === 'Power_detail') {
        routeData = this.$router.resolve({ name: pageName, params: { id: this.info.id } })
      } else {
        routeData = this.$router.resolve({ name: pageName, query: { power: this.info.id } })
      }
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.power_view {
  margin: 0px 20px 20px 0px;
  width: 280px;
  display: inline-block;
  position: relative;
  font-size: 14px;
  cursor:pointer;
}
.title{
  display: inline-block;
  /* padding: 0px 0px 10px 0px; */
  font-size: 18px;
  /* color: #ffd04b; */
  vertical-align: middle;
  font-weight: bold;
  /* color: #fff; */
}
.one_power{
  /* background: #50b7c1; */
  /* color: #ddd; */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(80, 183, 193,0.25);
}
.minzu_2{
  color: rgba(193, 67, 212, 0.6);
}
.minzu_1{
  color:  #50b7c1;
}
/* }
.progress-wrapper {
  display: inline-block;
  position: absolute;
  top: -13px;
  left: -13px;
  width: 40px;
  height: 40px;
  transform: scale(0.32);
  transform-origin: center;
}
*/

.circlex{
  position: absolute;
  width: 42px;
  height: 42px;
  display: inline-block;
  top: 10px;
  right: 10px;
}

.progress-wrapper {
  display: inline-block;
  position: absolute;
  top: -12px;
  left: -12px;
  width: 40px;
  height: 40px;
  transform: scale(0.32);
  transform-origin: center;
}
.circle_text{
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 125px;
  /* color: #f5f5f5; */
  font-size: 20px;
}
.circle_text span{
  font-size: 36px;
  display: inline-block;
  padding: 0px 4px;
}
.capital,.years{
  display: inline-block;
  font-size: 14px;
}
.capital{
  width: 190px;
}
.years{
  width: 70px;
}
.years{
  text-align: left;
}
.color_w1{
  color: #ccc;
}

.color_pink{
  color: pink;
  border: 1px solid pink;

}
.color_blue{
  color: rgb(21, 101, 158);
  border: 1px solid rgb(21, 101, 158);
}
.color_lightblue{
  color: lightblue;
  border: 1px solid lightblue;

}
.color_green{
  color: rgb(203, 167, 37);
  border: 1px solid rgb(203, 167, 37);

}
.color_yellow{
  color: rgb(190, 206, 23);
  border: 1px solid rgb(190, 206, 23);

}
.color_orange{
  color: orange;
  border: 1px solid orange;

}

.perosn_year_none{
  background-color: #d41616;

}
.perosn_breed{
  color: #888;
  box-shadow: 0px 0px 2px #999 inset;
}
.perosn_die{
  background-color: #bcbcbc;
}
.name_word,.year{
  display: inline-block;
  vertical-align: middle;
}
.year{
  padding-left: 3px;
}
.year span{
  display: block;
  font-size:12px;
  text-align: center;
  line-height: 12px;
  -webkit-transform:scale(0.9);

}
.year span.year_tag{
  -webkit-transform:scale(0.8);

}
</style>
